







































import { Component, Vue } from 'vue-property-decorator';
import RegisterLayout from "@/components/auth/register/RegisterLayout.vue";
import NorthStarService from "@/NorthStarService";
import {NorthStar} from "@/typescript/types";
import Board from "@/components/board/Board.vue";


@Component({
  components: {RegisterLayout, Board},
})
export default class GoalCreate extends Vue {
  northStarInput: string | null = null;
  service: NorthStarService | null = null;

  northStarAdded: boolean = false;


  mounted() {
    this.service = new NorthStarService(this.$apollo);
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.northStarCreate.focus();
    });
  }

  get workspace() {
    return this.$store.state.workspace
  }

  get board() {
    return this.$store.state.board
  }

  createGoal() {
  
    this.service?.createNorthStar(this.workspace, this.board, this.northStarInput!).then(({data}) => {
     
      this.addNorthStar(data.createNorthStar);
    })
  }

  addNorthStar(northStar: NorthStar): void {
    this.service?.addBoardToNorthStar([this.board], northStar).then(() => {
      this.$emit('completed', true)
     
      this.$buefy.toast.open({
        message: 'Goal created!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    })
    this.northStarInput = null;
    this.northStarAdded = true;
  }

  nextPage() {
    this.$router.push({ name: 'members-invite'});
  }
}
